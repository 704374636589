<template>
  <v-row
    style="background-color: white"
    class="elevation-3 mt-3 mb-1 rounded-xl pa-3"
  >
    <v-col>
      <!-- DIALOG INICIAL COM GMAP TRACKER -->
      <v-dialog v-model="adDialog" persistent width="45%">
        <v-card class="pb-6 pl-6 pr-6 rounded-lg">
          <v-card-title>
            <span style="color: DarkCyan" class="text-h5"
              >Insira a localidade</span
            >

            <v-spacer></v-spacer>

            <v-btn color="white" text @click="adDialog = false">
              <v-icon color="#5E6278"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-divider class="mb-4"></v-divider>

          <v-row>
            <v-col cols="12">
              <div
                class="rounded-lg v-input v-input--hide-details theme--light v-text-field v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"
              >
                <div class="v-input__control">
                  <div class="v-input__slot" style="background-color: #f3f6f9">
                    <div class="v-text-field__slot">
                      <gmap-autocomplete
                        @place_changed="initMarker"
                        placeholder="Digite o nome do estado, cidade ou bairro de onde deseja anunciar."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-btn
                dark
                rounded-lg
                @click="
                  addLocationMarker();
                  adDialog = false;
                "
                color="green"
              >
                Encontrar telas
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col>
          <v-row>
            <v-col cols="8">
              <span class="text-h4 font-weight-bold" style="color: #545871"
                >Gostou de alguma tela?</span
              >
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="auto">
              <v-btn
                color="#cde3e4"
                dark
                fab
                tile
                small
                class="ma-0 rounded-button"
                @click="filterSpecialties = true"
              >
                <v-icon dark color="#8fc2c9"> mdi-filter-outline </v-icon>
                <v-badge
                  bottom
                  overlap
                  offset-x="5"
                  offset-y="-10"
                  content="5"
                  color="#35909D"
                ></v-badge>
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                color="green"
                rounded-xl
                class="white--text"
                @click="adDialog = true"
                >Nova pesquisa
                <v-icon dark> mdi-gesture-double-tap </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- <p>Você selecionou <b>R$ 0,00</b> de <b>R$ 50,00</b>/dia</p> -->

          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="9" class="mr-n12 mb-0 mt-5">
              <span class="font-italic">* Anúncio sujeito a aprovação.</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- DIALOG DO BTN FILTER -->
      <v-dialog v-model="filterSpecialties" width="40%" persistent>
        <v-card class="pl-4 pr-4 pb-4">
          <v-card-title>
            <span style="color: DarkCyan" class="text-h5"
              >Filtrar por Especialidade</span
            >
          </v-card-title>

          <v-divider class="mb-3"></v-divider>

          <v-row>
            <v-col>
              <v-treeview selectable :items="specialtiesItems"></v-treeview>
            </v-col>
          </v-row>

          <v-divider class="mt-3 mb-3"></v-divider>

          <v-row>
            <v-col cols="1">
              <v-btn
                class="rounded black--text"
                color="darken-1"
                dense
                tile
                @click="filterSpecialties = false"
              >
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-row>
        <!-- GOOGLE MAP -->
        <v-col cols="6">
          <v-row>
            <v-col>
              <gmap-map
                :zoom="14"
                :center="center"
                style="width: 100%; height: 410px"
              >
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in locationMarkers"
                  :position="m.position"
                  @click="center = m.position"
                ></gmap-marker>
              </gmap-map>
            </v-col>
          </v-row>
        </v-col>

        <!-- GRID DAS TELAS -->
        <template>
          <v-col cols="6" class="scrollable">
            <vue-scroll :ops="ops">
              <v-row style="width: 100%" class="pl-2">
                <v-col>
                  <v-card
                    outlined
                    hover
                    rounded="xl"
                    @click.stop="drawer = !drawer"
                  >
                    <v-card-title
                      class="text-body-1 font-weight-medium mb-2 ml-4"
                    >
                      {{ adCardsName }}
                    </v-card-title>
                    <v-card-subtitle>
                      <v-icon small color="blue lighten-2">
                        mdi-map-marker
                      </v-icon>
                      {{ adCardsAdress }}
                    </v-card-subtitle>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col>
                                  <v-icon small color="blue lighten-2">
                                    mdi-television
                                  </v-icon>
                                  Exibições por dia:
                                  <span class="font-weight-bold">
                                    {{ adCardsViewsPerDay }}</span
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="mt-n4">
                                  <v-icon small color="blue lighten-2">
                                    mdi-eye
                                  </v-icon>
                                  Número de impactos:
                                  <span class="font-weight-bold">
                                    {{ adCardsImpactNumbers }}</span
                                  >
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col class="mt-n9 ml-16 mr-n10 mb-n4">
                              <v-row>
                                <v-col class="mb-n4 mt-n2">
                                  <v-checkbox
                                    hide-details
                                    label="Selecionar"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <!-- <v-row>
                            <v-col>
                              <span class="text-decoration-line-through"
                                >De
                                <b class="grey--text text-h6">R$ 8,06</b>/dia
                              </span>
                              <p
                                class="text-body-1 indigo--text text--darken-4"
                              >
                                Por
                                <b class="text-h5">R$ 4,03</b>/dia
                              </p>
                            </v-col>
                          </v-row> -->
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row style="width: 100%" class="pl-2">
                <v-col>
                  <v-card
                    outlined
                    hover
                    rounded="xl"
                    @click.stop="drawer = !drawer"
                  >
                    <v-card-title
                      class="text-body-1 font-weight-medium mb-2 ml-4"
                    >
                      1199_Espaço Performance (3 TV's)
                    </v-card-title>
                    <v-card-subtitle>
                      <v-icon small color="blue lighten-2">
                        mdi-map-marker
                      </v-icon>
                      R. das Dálias, 576 - Pituba, Salvador - BA, 41810-040,
                      Brasil
                    </v-card-subtitle>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col>
                                  <v-icon small color="blue lighten-2">
                                    mdi-television
                                  </v-icon>
                                  Exibições por dia:
                                  <span class="font-weight-bold">72</span>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="mt-n4">
                                  <v-icon small color="blue lighten-2">
                                    mdi-eye
                                  </v-icon>
                                  Número de impactos:
                                  <span class="font-weight-bold">648</span>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col class="mt-n9 ml-16 mr-n10 mb-n4">
                              <v-row>
                                <v-col class="mb-n4 mt-2">
                                  <v-checkbox
                                    hide-details
                                    label="Selecionar"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <!-- <v-row>
                            <v-col>
                              <span class="text-decoration-line-through"
                                >De
                                <b class="grey--text text-h6">R$ 32,40</b>/dia
                              </span>
                              <p
                                class="text-body-1 indigo--text text--darken-4"
                              >
                                Por
                                <b class="text-h5">R$ 16,20</b>/dia
                              </p>
                            </v-col>
                          </v-row> -->
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row style="width: 100%" class="pl-2">
                <v-col>
                  <v-card
                    outlined
                    hover
                    rounded="xl"
                    @click.stop="drawer = !drawer"
                  >
                    <v-card-title
                      class="text-body-1 font-weight-medium mb-2 ml-4"
                    >
                      1199_Espaço Performance (3 TV's)
                    </v-card-title>
                    <v-card-subtitle>
                      <v-icon small color="blue lighten-2">
                        mdi-map-marker
                      </v-icon>
                      R. das Dálias, 576 - Pituba, Salvador - BA, 41810-040,
                      Brasil
                    </v-card-subtitle>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col>
                              <v-row>
                                <v-col>
                                  <v-icon small color="blue lighten-2">
                                    mdi-television
                                  </v-icon>
                                  Exibições por dia:
                                  <span class="font-weight-bold">72</span>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="mt-n4">
                                  <v-icon small color="blue lighten-2">
                                    mdi-eye
                                  </v-icon>
                                  Número de impactos:
                                  <span class="font-weight-bold">648</span>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col class="mt-n9 ml-16 mr-n10 mb-n4">
                              <v-row>
                                <v-col class="mb-n4 mt-2">
                                  <v-checkbox
                                    hide-details
                                    label="Selecionar"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <!-- <v-row>
                            <v-col>
                              <span class="text-decoration-line-through"
                                >De
                                <b class="grey--text text-h6">R$ 32,40</b>/dia
                              </span>
                              <p
                                class="text-body-1 indigo--text text--darken-4"
                              >
                                Por
                                <b class="text-h5">R$ 16,20</b>/dia
                              </p>
                            </v-col>
                          </v-row> -->
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </vue-scroll>
          </v-col>
        </template>
      </v-row>

      <!-- INFORMAÇÕES DA TELA - DRAWER-->
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        width="300px"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="mb-2">
              {{ adCardsName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ adCardsAdress }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-carousel
          cycle
          height="300"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet :color="colors[i]" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-h4">{{ slide }} Slide</div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <v-row class="ml-1">
          <v-col>
            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-television
                    </v-icon>
                    <span> &nbsp; {{ drawerDisplays }} Tela(s) </span>
                  </template>
                  <span>Quantidade de displays no conjunto</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-map-marker-account-outline
                    </v-icon>
                    <span> &nbsp; {{ drawerSocialClass }} </span>
                  </template>
                  <span>Classe Social</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-currency-usd
                    </v-icon>
                    <span> &nbsp;R$ 40,00 por mil impactos. </span>
                  </template>
                  <span>Valor para mil impactos</span>
                </v-tooltip>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-chart-line
                    </v-icon>
                    <span> &nbsp;{{ drawerMonthlyFlow }} pessoas/mês </span>
                  </template>
                  <span>Fluxo mensal</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-hospital-building
                    </v-icon>
                    <span> &nbsp;{{ drawerCategories }} </span>
                  </template>
                  <span>Categoria</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-format-list-bulleted-type
                    </v-icon>
                    <span> &nbsp;{{ drawerSpecialties }} </span>
                  </template>
                  <span>Especialidades</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-fit-to-screen-outline
                    </v-icon>
                    <span> &nbsp;{{ drawerScreenProportion }}</span>
                  </template>
                  <span>Proporção</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="blue darken-3">
                      mdi-clock-time-four-outline
                    </v-icon>
                    <span>
                      &nbsp;Aberto das {{ drawerWorkingTimeOpening }}h às
                      {{ drawerWorkingTimeClose }}h
                    </span>
                  </template>
                  <span>Horário de funcionamento</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider class="mb-4 mt-4"></v-divider>

        <!-- <v-row class="ml-1">
          <v-col>
            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green" dark block v-bind="attrs" v-on="on">
                      <v-icon>mdi-cart-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Display no carrinho</span>
                </v-tooltip>
              </v-col>

              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      <v-icon>mdi-share-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Compartilhar nas redes sociais</span>
                </v-tooltip>
              </v-col>
            </v-row>

            
          </v-col>
        </v-row> -->
      </v-navigation-drawer>

      <v-row class="mt-2">
        <v-col cols="auto">
          <v-btn dark large color="green" @click="adInfo = true">
            Anunciar</v-btn
          >
        </v-col>
        <v-col cols="1">
          <v-btn dark large color="#35909D" @click="adItemsSelected = true"
            >Itens Selecionados</v-btn
          >
        </v-col>
      </v-row>

      <!-- INFORMAÇÕES DO ANÚNCIO -->
      <v-dialog v-model="adInfo" persistent>
        <v-card class="rounded-lg pb-6 pl-6 pr-6">
          <v-card-title>
            <span style="color: DarkCyan" class="text-h5"
              >Informações do anúncio</span
            >

            <v-spacer></v-spacer>

            <v-btn color="white" text @click="adInfo = false">
              <v-icon color="#5E6278"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="mb-4"></v-divider>

          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Nome do anúncio"
                v-model="form.adInfoName"
                required
                clearable
                rounded
                filled
                dense
                hide-details="auto"
                background-color="#F5F8FA"
                hint="Digite um título para seu anúncio para identifica-lo mais facilmente"
                persistent-hint
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                label="Descrição do anúncio"
                v-model="adInfoDescription"
                rounded
                filled
                dense
                background-color="#F5F8FA"
                rows="2"
                row-height="15"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-divider class="pb-3"></v-divider>

          <v-row>
            <v-col cols="auto">
              <v-btn
                tile
                class="rounded white--text pa-4"
                color="#3DA539"
                @click="adInfo = false"
              >
                Salvar
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                tile
                class="rounded white--text pa-4"
                color="red"
                @click="adInfo = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <!-- DIALOG DO BTN ITENS SELECIONADOS -->
      <v-dialog v-model="adItemsSelected" width="40%">
        <v-card class="pl-4 pr-4 pb-4">
          <v-card-title>
            <span style="color: DarkCyan" class="text-h5"
              >Praças selecionadas</span
            >
          </v-card-title>

          <v-divider class="mb-3"></v-divider>

          <v-row>
            <v-col>
              <v-toolbar color="teal" dark>
                <v-icon class="mr-2"> mdi-map-search-outline </v-icon>

                <v-toolbar-title>Localidades</v-toolbar-title>
              </v-toolbar>

              <v-list>
                <v-list-group :value="true" prepend-icon="mdi-map-marker">
                  <template v-slot:activator>
                    <v-list-item-title>Estados</v-list-item-title>
                  </template>

                  <v-list-group :value="false" no-action sub-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Bahia</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="([title, icon], i) in admins"
                      :key="i"
                      link
                    >
                      <v-list-item-title v-text="title"></v-list-item-title>

                      <v-list-item-icon>
                        <v-icon v-text="icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>

                  <v-list-group no-action sub-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Pernambuco</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="([title, icon], i) in cruds"
                      :key="i"
                      link
                    >
                      <v-list-item-title v-text="title"></v-list-item-title>

                      <v-list-item-icon>
                        <v-icon v-text="icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>
                </v-list-group>
              </v-list>
            </v-col>
          </v-row>

          <v-divider class="mt-3 mb-3"></v-divider>

          <v-row>
            <v-col cols="auto">
              <v-btn
                class="rounded black--text"
                color="darken-1"
                dense
                tile
                @click="adItemsSelected = false"
              >
                Fechar
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-col>
              <v-btn class="rounded white--text" color="green" dark dense tile>
                Telas selecionadas: {{ drawerDisplays }}
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style lang="stylus">
.scrollable {
   overflow-y: hidden;
   overflow-x: hidden;
   max-height: 65vh;
}
</style>

<script>
// import { ValidationObserver } from "vee-validate";
// import BaseInputValidator from "../../components/UI/BaseInputValidator";

export default {
  name: "AdvertiseSelection",
  data() {
    return {
      adDialog: true,
      adInfo: false,

      adInfoDescription: "Opcional",
      adItemsSelected: false,
      filterSpecialties: false,
      valid: true,
      map: null,

      // specialtiesItemsDrawer: ["Hemato e imunologia", "Cardiologia"],

      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right",
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "12px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "0px",
          keepShow: false,
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: "8px",
          disable: false,
        },
        scrollButton: {
          enable: true,
          background: "rgb(3, 185, 118)",
          opacity: 1,
          step: 180,
          mousedownStep: 30,
        },
      },
      form: {
        geoLocalization: {
          lat: "",
          long: "",
        },
        class: "",
        avgCustomer: 0,
        adInfoName: "Anúncio-164158",
      },
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      isValidStepOne: false,
      activator: null,
      attach: null,
      editing: null,
      editingIndex: -1,
      nonce: 1,
      menu: false,
      drawer: null,
      // items: [
      //   {
      //     action: "mdi-map-marker",
      //     items: [{ title: "Salvador" }],
      //     title: "Bahia",
      //   },
      //   {
      //     action: "mdi-map-marker",
      //     active: true,
      //     items: [{ title: "Recife" }],
      //     title: "Pernambuco",
      //   },
      // ],

      admins: [
        ["Lauro de Freitas", "mdi-map-marker-left-outline"],
        ["Camaçari", "mdi-map-marker-left-outline"],
      ],
      cruds: [
        ["Recife", "mdi-map-marker-left-outline"],
        ["Olinda", "mdi-map-marker-left-outline"],
      ],

      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],

      slides: ["First", "Second", "Third", "Fourth", "Fifth"],

      adCardsName: "MaisBrasil Estrela Brasil Filial - 0159 (1 TV)",
      adCardsAdress:
        "Av. Pref. Celso Alves Pinheiro da Silva, 300 - Centro, Lauro de Freitas - BA, 41301-110, Brasil",
      adCardsViewsPerDay: "144",
      adCardsImpactNumbers: "202",

      // adCards: [
      //   {
      //     id: 1,
      //     name: "MaisBrasil",
      //     adress: "Alouuu",
      //     viewsPerDay: "144",
      //     impactNumbers: "202",
      //   },
      // ],

      drawerDisplays: "1",
      drawerSocialClass: "B",
      drawerMonthlyFlow: "2.000",
      drawerCategories: "Hospital",
      drawerSpecialties: "Cardiologia, Hematologia, Oncologia",
      drawerScreenProportion: "16:9",
      drawerWorkingTimeOpening: "08:00",
      drawerWorkingTimeClose: "20:00",

      specialtiesItems: [
        {
          id: 1,
          name: "BIOMEDICINA",
          children: [
            { id: 1.1, name: "BIOLOGIA MOLECULAR" },
            { id: 1.2, name: "PATOLOGIA HUMANA EXPERIMENTAL" },
          ],
        },
        {
          id: 2,
          name: "PATOLOGIA",
          children: [
            { id: 2.1, name: "CLÍNICA" },
            { id: 2.2, name: "MEDICINA LABORATORIAL" },
          ],
        },
        {
          id: 3,
          name: "ANGIOLOGIA",
        },
        {
          id: 4,
          name: "DERMATOLOGIA",
        },
        {
          id: 5,
          name: "HEMATOLOGIA",
          children: [{ id: 5.1, name: "HEMOTERAPIA" }],
        },
        {
          id: 6,
          name: "NEUROLOGIA",
        },
      ],
    };
  },

  // components: {
  //   ValidationObserver,
  //   BaseInputValidator,
  // },

  mounted() {
    this.locateGeoLocation();
  },

  watch: {
    form: {
      handler: function (val) {
        if (val.name && val.categories.length > 0) {
          this.isValidStepOne = true;
        } else {
          this.isValidStepOne = false;
        }
      },
      deep: true,
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },

  methods: {
    initMarker(loc) {
      console.log(loc);
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.locationMarkers.push({ position: marker });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    validateStepOne() {
      console.log(this.$refs.observer.validate());
      return true;
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
  },
  filter(item, queryText, itemText) {
    if (item.header) return false;

    const hasValue = (val) => (val != null ? val : "");

    const text = hasValue(itemText);
    const query = hasValue(queryText);

    return (
      text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    );
  },
};
</script>
