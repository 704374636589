<template>
  <div>
    <v-row>
      <router-view @created="showSnack"></router-view>
    </v-row>
    <v-row>
      <v-col>
        <ActionBarCrud
          create
          :advancedFilter="false"
          routeName="CreateInstagram"
        >
          <template v-slot:simpleFilter>
            <v-col cols="auto">
              <v-text-field
                prepend-icon="mdi-magnify"
                label="ID/Nome"
                v-model="search"
                filled
                rounded
                dense
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="instagram"
          :search="search"
          item-key="_id"
          class="elevation-8 rounded-lg"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <ActionButtons
              :item="item"
              editRoute="EditInstagram"
              deleteRoute="DeleteInstagram"
            />
          </template>
          <template v-slot:[`item.instagram`]="{ item }">
            <span class="d-block">
              {{ item.name }}
              <small class="d-block">{{ item.group }}</small>
            </span>
          </template>
          <template v-slot:[`item.url`]="{ item }">
            <span class="d-block">
              {{ item.url }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <router-view @created="showSnack"></router-view>
    <v-snackbar v-model="snackbar" color="green">
      Instagram {{ data.name }} {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import ActionBarCrud from "../../components/UI/ActionBarCrud";
import ActionButtons from "../../components/DataTable/ActionButtons";
import ContentService from "../contents/service";
export default {
  data() {
    return {
      dialog: false,
      search: "",
      data: {},

      headers: [
        { text: "Nome", value: "name" },
        { text: "Instagram", value: "url" },
        { text: "Operações", value: "actions" },
      ],
      instagram: [],
      snackbar: false,
      typeForm: "criado",
    };
  },
  components: {
    ActionBarCrud,
    ActionButtons,
  },
  methods: {
    async showSnack(object) {
      this.data = object.instagram;
      this.snackbar = true;
      if (object.typeForm === "CreateInstagram") this.typeForm = "criado";
      if (object.typeForm === "EditInstagram") this.typeForm = "editado";
      if (object.typeForm === "DeleteInstagram") this.typeForm = "deletado";
      await this.getInstagram();
    },
    resetForm() {
      this.form = Object.assing({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async getInstagram() {
      try {
        const instagram = await ContentService.getContents({
          type: "INSTAGRAM",
        });
        this.instagram = instagram.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.getInstagram();
  },
};
</script>
