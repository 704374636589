<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="4" align-self="center">
        <v-card class="justify-center" rounded="xl" ref="form" elevation="3">
          <v-form ref="form" v-model="valid" lazy-validation>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <v-card-text class="pa-8">
                  <v-col cols="12">
                    <v-img
                      class="mx-auto"
                      height="201px"
                      width="193px"
                      src="../../../public/img/icons/simbolo@2x.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="12 " class="pt-0 pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Usuário"
                      rules="required"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="Usuário"
                        required
                        autocomplete="off"
                        dense
                        form-control
                        class="rounded-lg"
                        background-color="#F5F8FA"
                        rounded
                        filled
                        clearable
                        hint="Digite seu nome de usuário"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Senha"
                      rules="required"
                    >
                      <v-text-field
                        v-model="password"
                        :error-messages="errors"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Senha"
                        hint="Digite a senha"
                        background-color="#F5F8FA"
                        class="rounded-lg"
                        rounded
                        filled
                        autocomplete="off"
                        form-control
                        dense
                        clearable
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      class="rounded-lg white--text"
                      block
                      x-large
                      :disabled="invalid"
                      color="#35A53C"
                      light
                      @click="submit"
                    >
                      Entrar
                    </v-btn>
                  </v-col>
                </v-card-text>
              </form>
            </validation-observer>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="2000" :color="snackColor">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { required } from "vee-validate/dist/rules";
// import auth from "./service/index";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      show1: false,
      snackColor: "green",
      password: "",
      rules: {
        required: (value) => !!value || "Senha obrigatório.",
        emailMatch: () => `O usuário e/ou a senha estão incorretos`,
      },
      valid: true,
      email: "",
      select: null,
      snackbar: false,
      text: "Seus dados foram enviados!",
    };
  },

  mounted() {},

  methods: {
    async submit() {
      this.$refs.observer.validate();
      try {
        await this.$store.dispatch("$_auth/getAuthenticate", {
          email: this.email,
          password: this.password,
        });
        this.text = "Seja bem vindo";
        this.snackbar = true;
        this.snackColor = "green";
        this.$router.push({ name: "Content" });
      } catch (error) {
        console.log(error);
        this.text = "Usuário e/ou senha incorretos!";
        this.snackbar = true;
        this.snackColor = "red";
      }
    },
    clear() {
      this.name = "";
    },
  },
};
</script>

<style lang="stylus">
.v-application {
background-color: #F3F6F9 !important;
}
</style>
