<template>
  <v-card class="rounded-lg pl-6 pr-6 pb-6">
    <v-card-title>
      <span style="color: DarkCyan" class="text-h5">Filtro Avançado</span>

      <v-spacer></v-spacer>

      <v-btn color="white" text @click="closeEmitFilter">
        <v-icon color="#5E6278"> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mb-4"></v-divider>

    <v-row>
      <v-col cols="9">
        <v-text-field
          label="Nome:"
          v-model="form.name"
          required
          clearable
          rounded
          filled
          dense
          hide-details
          background-color="#F5F8FA"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Agendamento"
              :prepend-icon="null"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              rounded
              filled
              dense
              clearable
              hide-details
              background-color="#F5F8FA"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
            locale="pt-br"
            :first-day-of-week="1"
            multiple
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-autocomplete
          label="Agência:"
          v-model="form.categories"
          auto-select-first
          chips
          clearable
          deletable-chips
          filled
          multiple
          required
          rounded
          dense
          hide-details
          :items="agencyItems"
          background-color="#F5F8FA"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-autocomplete
          label="Clientes:"
          v-model="form.categories"
          auto-select-first
          chips
          clearable
          deletable-chips
          filled
          multiple
          required
          rounded
          dense
          hide-details
          :items="customerItems"
          background-color="#F5F8FA"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-autocomplete
          label="Categoria:"
          v-model="form.categories"
          uto-select-first
          small-chips
          clearable
          deletable-chips
          filled
          multiple
          required
          rounded
          dense
          hide-details
          :items="categoryItems"
          background-color="#F5F8FA"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-checkbox v-model="checkbox" :label="`Vídeo`" disabled> </v-checkbox>
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="checkbox" :label="`Áudio`" disabled> </v-checkbox>
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="checkbox" :label="`HTML`" disabled> </v-checkbox>
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="checkbox" :label="`Imagem`" disabled> </v-checkbox>
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="checkbox" :label="`Conteúdo Dinâmico`" disabled>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="pb-3"></v-divider>
    <v-row>
      <v-col cols="auto">
        <v-btn
          tile
          class="rounded white--text pa-4"
          small
          color="#35909D"
          @click="closeEmitFilter"
        >
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="stylus"></style>

<script>
export default {
  name: "CreateContent",
  data() {
    return {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,

      checkbox: false,
      dialog: true,
      categoryItems: ["Ta", "Ficando", "Bom", "Vitu", "Xesk", "EOQ", "Vrau"],
      schedulingItems: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
      agencyItems: [
        "Bradesco",
        "Inter",
        "Santander",
        "Itaú",
        "Banco do Brasil",
      ],
      customerItems: ["Vitor", "Mauro", "Felipe", "Matheus", "Campos"],
      form: {
        name: "",
        duration: "",
        categories: [],
        days: [],
        file: "",
      },
      fileName: "",
    };
  },
  methods: {
    handleFile(e) {
      // const selectedFile = e.target.files[0];
      console.log(e);
      this.createBase64File(e);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.file = e.target.result;
      };
      reader.readAsBinaryString(fileObject);
    },
    closeEmitFilter() {
      this.$emit("closeFilter", true);
    },
  },
};
</script>
