<template>
  <div>
    <v-dialog persistent v-model="dialog">
      <AdvancedFilter @closeFilter="dialog = false"></AdvancedFilter>
    </v-dialog>
    <v-row>
      <router-view @created="showSnack"></router-view>
    </v-row>
    <v-row>
      <v-col>
        <ActionBarCrud
          :create="true"
          :advancedFilter="false"
          routeName="CreateContent"
          @openFilter="dialog = true"
        >
          <template v-slot:simpleFilter>
            <v-col cols="6">
              <v-text-field
                append-icon="mdi-magnify"
                label="Buscar"
                filled
                rounded
                dense
                v-model="search"
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Carregando..."
          v-model="selected"
          :headers="headers"
          :search="search"
          :items="preview.docs"
          hide-default-footer
          item-key="_id"
          show-select
          class="elevation-8 rounded-lg rounded-b-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <ActionButtons
              :item="item"
              editRoute="EditContent"
              deleteRoute="DeleteContent"
              duplicateRoute="DuplicateContent"
              downloadRoute="DownloadRoute"
              @download="downlaodEvent"
            />
          </template>
          <template v-slot:[`item.file`]="{ item }">
            <v-img width="160px" height="90px" :src="item.thumbnail"></v-img>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <div class="text-center white rounded-lg elevation-8 rounded-t-0 pa-2">
          <v-pagination
            v-model="page"
            circle
            :length="preview.totalPages"
            total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" color="green">
      Conteudo {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ActionBarCrud from "../../components/UI/ActionBarCrud";
import ActionButtons from "../../components/DataTable/ActionButtons";
import AdvancedFilter from "./filtercontent";
import ContentService from "./service";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      singleSelect: false,
      selected: [],
      snackbar: false,
      data: {},
      preview: [],
      page: 1,
      search: "",
      typeForm: "",
      headers: [
        {
          text: "Preview",
          value: "file",
        },
        { text: "Conteúdo", value: "name" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  components: {
    ActionBarCrud,
    ActionButtons,
    AdvancedFilter,
  },
  methods: {
    async showSnack(object) {
      this.data = object.content;
      this.snackbar = true;
      if (object.typeForm === "EditContent") this.typeForm = "editado";
      if (object.typeForm === "DeleteContent") this.typeForm = "deletado";
      if (object.typeForm === "CreateContent") this.typeForm = "criado";
      //if (object.typeForm === "CopyContent") this.typeForm = "copiado";
      await this.getContents();
    },
    resetForm() {
      this.form = Object.assing({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async getContents(query) {
      this.loading = true;
      try {
        const content = await ContentService.getContents({
          type: "VIDEO",
          status: true,
          ...query,
        });
        content.data.docs.forEach((element) => {
          console.log(content);
          if (element.thumbnail) {
            element.thumbnail =
              process.env.VUE_APP_AWS_CLOUDFRONT_URL +
              element.thumbnail.split(process.env.VUE_APP_AWS_S3_URL)[1];
          }
        });
        console.log(content);
        this.preview = content.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async verifyFormat(file, format) {
      const fileFormat = file.split("/")[0];
      if (fileFormat === format) return true;
      return false;
    },
  },
  async mounted() {
    if (!this.$route.query.page) {
      this.$router.push({ path: "/contents", query: { page: 1 } });
    }
    await this.getContents(this.$route.query);
  },
  watch: {
    page: {
      handler: async function (newPage) {
        this.$router.push({ path: "/contents", query: { page: newPage } });
        this.preview.docs = [];
        await this.getContents({ page: newPage });
      },
      deep: true,
    },
  },
};
</script>
