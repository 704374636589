<template>
  <div>
    <v-btn
      v-if="editRoute"
      icon
      :to="{ name: editRoute, params: { id: item._id } }"
    >
      <v-icon> mdi-pencil-outline </v-icon>
    </v-btn>
    <v-btn
      v-if="previewRoute"
      icon
      :to="{ name: previewRoute, params: { id: item._id } }"
    >
      <v-icon> mdi-filmstrip </v-icon>
    </v-btn>

    <v-btn
      v-if="copyRoute"
      icon
      :to="{ name: copyRoute, params: { id: item._id } }"
    >
      <v-icon> mdi-content-copy </v-icon>
    </v-btn>
    <v-btn
      v-if="deleteRoute"
      icon
      @click.stop
      :to="{ name: deleteRoute, params: { id: item._id } }"
    >
      <v-icon> mdi-delete-outline </v-icon>
    </v-btn>
    <v-btn
      v-if="androidRoute"
      icon
      :to="{ name: androidRoute, params: { id: item._id } }"
    >
      <v-icon> mdi-android </v-icon>
    </v-btn>
    <v-btn
      v-if="playlistButton"
      icon
      :to="{ name: playlistButton, params: { id: item._id } }"
    >
      <v-icon> mdi-playlist-play </v-icon>
    </v-btn>
    <a
      :href="linkS3(item.file)"
      v-if="downloadRoute"
      icon
      @click="$emit('download', item)"
    >
      <v-icon> mdi-download</v-icon>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
    deleteRoute: {
      type: String,
    },
    editRoute: {
      type: String,
    },
    editplaylistRoute: {
      type: String,
    },
    androidRoute: {
      type: String,
    },
    copyRoute: {
      type: String,
    },
    previewRoute: {
      type: String,
    },
    playlistButton: {
      type: String,
    },
    downloadRoute: {
      type: String,
    },
  },

  methods: {
    linkS3(link) {
      return (
        process.env.VUE_APP_AWS_CLOUDFRONT_URL +
        link.split(process.env.VUE_APP_AWS_S3_URL)[1]
      );
    },
  },
};
</script>
