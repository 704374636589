<template>
  <div>
    <v-row>
      <v-col>
        <ActionBarCrud
          create
          :advancedFilter="false"
          routeName="CreateTerminal"
        >
          <template v-slot:simpleFilter>
            <v-col cols="auto">
              <v-text-field
                prepend-icon="mdi-magnify"
                label="ID/Nome"
                filled
                rounded
                dense
                v-model="search"
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
            <v-col>
              <v-switch
                v-model="filter.status"
                color="primary"
                :label="`Status: ${filter.status}`"
                true-value="on"
                false-value="off"
              ></v-switch>
            </v-col>
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="terminal.docs"
          :loading="loading"
          hide-default-footer
          item-key="_id"
          class="elevation-8 rounded-lg"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <ActionButtons
              :item="item"
              editRoute="EditTerminal"
              deleteRoute="DeleteTerminal"
              playlistButton="Schedule"
            />
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip v-if="item.status === 'on'" class="green" outlined>
              <v-icon class="green--text" dark> mdi-television </v-icon>
            </v-chip>

            <v-chip v-if="item.status === 'off'" class="red" outlined>
              <v-icon class="red--text" dark> mdi-television-off </v-icon>
            </v-chip>
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            <v-chip
              outlined
              :class="convertDatetimeToBrl(item.updatedAt, true)"
              >{{ convertDatetimeToBrl(item.updatedAt) }}</v-chip
            >
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <div class="text-center white rounded-lg elevation-8 rounded-t-0 pa-2">
          <v-pagination
            v-model="page"
            circle
            :length="terminal.totalPages"
            total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <router-view @created="showSnack"></router-view>
    <v-snackbar v-model="snackbar" color="green">
      Terminal {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="stylus"></style>

<script>
import ActionBarCrud from "../../components/UI/ActionBarCrud";
import ActionButtons from "../../components/DataTable/ActionButtons";
import TerminalService from "./service";
export default {
  data() {
    return {
      searchTimer: null,
      data: {},
      search: "",
      filter: {
        status: "",
      },
      page: 1,
      loading: false,
      headers: [
        { text: "Codigo do terminal", value: "_id" },
        { text: "Informações do Terminal", value: "name" },
        { text: "Status", value: "status" },
        { text: "Ultima Sincronização", value: "updatedAt" },
        { text: "Ações", value: "actions" },
      ],
      terminal: [],
      snackbar: false,
      typeForm: "criado",
    };
  },
  components: {
    ActionBarCrud,
    ActionButtons,
  },
  methods: {
    async showSnack(object) {
      this.data = object.terminal;
      this.snackbar = true;
      if (object.typeForm === "EditTerminal") this.typeForm = "editado";
      if (object.typeForm === "CreateTerminal") this.typeForm = "criado";
      if (object.typeForm === "DeleteTerminal") this.typeForm = "deletado";
      if (object.typeForm === "CopyTerminal") this.typeForm = "copiado";
      await this.getTerminals();
    },
    resetForm() {
      this.form = Object.assing({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async getTerminals(query) {
      try {
        const terminal = await TerminalService.getTerminals(query);
        terminal.data.docs.forEach((element) => {
          if (element.contents.length > 0) {
            element.countContentsFromPlaylist = element.contents.length;
          }
        });
        this.terminal = terminal.data;
      } catch (error) {
        console.error(error);
      }
    },
    convertDatetimeToBrl(datetime, colorCheck) {
      const date = new Date(datetime);

      const now = new Date();

      const diff = now.getTime() - date.getTime();
      const diffInMinutes = Math.floor(diff / (1000 * 60));

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

      if (colorCheck) {
        if (diffInMinutes > 30) {
          return "red";
        } else {
          return "green";
        }
      }

      return formattedDate;
    },
  },
  watch: {
    search: {
      handler(newSearch) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(async () => {
          if (newSearch.length >= 3) {
            await this.getTerminals({ search: newSearch, populate: false });
          } else if (newSearch.length === 0) {
            await this.getTerminals({ populate: false });
          }
        }, 1000);
      },
      deep: true,
    },
    "filter.status": {
      handler: async function (val) {
        this.$router.push({
          path: "terminal",
          query: { status: val },
        });
        await this.getTerminals({ status: val });
        this.loading = false;
      },
      deep: true,
    },
    page: {
      handler: async function (newPage) {
        const queryParams = {
          page: newPage,
          populate: false,
        };

        if (this.search && this.search.length >= 3) {
          queryParams.search = this.search;
        }

        this.$router.push({ path: "/terminal", query: queryParams });
        this.terminal.docs = [];
        await this.getTerminals(queryParams);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getTerminals({ populate: false });
  },
};
</script>
