<template>
  <v-dialog persistent v-model="dialog" width="370" content-class="elevation-4">
    <v-form>
      <v-card width="370px" class="pa-3 rounded-lg">
        <!-- class="ma-1" dentro do v-card é a "caixa fantasma"
         também presente no DataTable!  -->
        <v-card-title class="text-h7 pa-1 ma-2">
          Preview de Notícia
        </v-card-title>
        <v-card-text style="height: 100px">
          <v-row>
            <v-col>
              <v-alert dense text>
                Notícia não disponível. É possível que não existam notícias
                válidas para a fonte RSS que deseja visualizar.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-col>
              <v-btn
                :to="{ name: 'Rss' }"
                height="35px"
                tile
                class="rounded white--text"
                small
                color="#3DA539"
              >
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="stylus">
.text-h7 {
  color: #5D5D5D;
  font-weight: 550 !important;
  font-size: 16px !important;
}
.strong {
  color: red;
}
</style>

<script>
export default {
  name: "DeleteRss",
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
