<template>
  <div>
    <v-row>
      <v-col>
        <ActionBarCrud
          routeName="CreateUser"
          :create="true"
          :advancedFilter="false"
        >
          <template v-slot:simpleFilter>
            <v-col cols="3">
              <v-text-field
                label="Nome / Email"
                filled
                :headers="headers"
                :items="name"
                v-model="search"
                rounded
                dense
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="users"
          item-key="name"
          class="elevation-8"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :to="{ name: 'EditUser', params: { id: item._id } }" icon>
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
            <v-btn icon :to="{ name: 'DeleteUser', params: { id: item._id } }">
              <v-icon> mdi-delete-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <router-view @created="showSnack"></router-view>
    <v-snackbar v-model="snackbar" color="green">
      Usuário {{ data.name }} {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import ActionBarCrud from "../../components/UI/ActionBarCrud";
import UsuarioService from "./service";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "E-mail (Login)", value: "email" },
        { text: "Ações", value: "actions" },
      ],
      snackbar: false,
      data: {},
      users: [],
      typeForm: "criado",
      name: "",
    };
  },
  components: {
    ActionBarCrud,
  },
  methods: {
    async showSnack(object) {
      this.data = object.user;
      this.snackbar = true;
      if (object.typeForm === "EditUser") this.typeForm = "editado";
      if (object.typeForm === "DeleteUser") this.typeForm = "deletado";
      await this.getUsers();
    },
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async getUsers() {
      try {
        const users = await UsuarioService.getUsers();
        this.users = users.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.getUsers();
  },
};
</script>
