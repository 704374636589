<template>
  <div>
    <v-row>
      <v-col>
        <ActionBarCrud
          routeName="CreateCustomer"
          :create="true"
          :advancedFilter="false"
        >
          <template v-slot:simpleFilter>
            <v-col cols="3">
              <v-text-field
                label="Nome fantasia"
                filled
                rounded
                dense
                v-model="search"
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="2">
              <v-text-field
                label="CNPJ"
                v-mask="'##.###.###/####-##'"
                type="text"
                v-model="cnpjInput"
                filled
                rounded
                dense
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="3">
              <v-autocomplete
                :items="groups"
                label="Ordenar por:"
                required
                hide-details
                rounded
                clearable
                filled
                dense
                background-color="#F5F8FA"
                full-width
              ></v-autocomplete>
            </v-col> -->
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="customers.docs"
          hide-default-footer
          item-key="name"
          class="elevation-8"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              :to="{ name: 'EditCustomer', params: { id: item._id } }"
              icon
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
            <v-btn
              :to="{ name: 'DeleteCustomer', params: { id: item._id } }"
              icon
            >
              <v-icon> mdi-delete-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <div class="text-center white rounded-lg elevation-8 rounded-t-0 pa-2">
          <v-pagination
            v-model="page"
            circle
            :length="customers.totalPages"
            total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <router-view @created="showSnack"></router-view>
    <v-snackbar v-model="snackbar" color="green">
      Cliente/Agência {{ data.name }} {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import CustomerService from "./service";
import ActionBarCrud from "../../components/UI/ActionBarCrud";
export default {
  data() {
    return {
      search: "",
      cnpjInput: "",
      snackbar: false,
      page: 1,
      data: {},
      customers: [],
      typeForm: "",
      groups: [
        "Código",
        "Razão social",
        "Nome fantasia",
        "Tipo - Agências primeiro",
        "Tipo - Clientes primeiro",
      ],
      headers: [
        {
          // text: "Código",
          align: "start",
          value: "name",
        },
        // { text: "Tipo", value: "type" },
        { text: "Razão social", value: "corporateName" },
        { text: "Nome fantasia", value: "fantasyName" },
        { text: "E-mail", value: "email" },
        { text: "Contato", value: "contactName" },
        { text: "Operações", value: "actions" },
      ],
    };
  },
  components: {
    ActionBarCrud,
  },
  methods: {
    async showSnack(object) {
      this.data = object.customers;
      this.snackbar = true;
      if (object.typeForm === "EditCustomer") this.typeForm = "editado";
      if (object.typeForm === "DeleteCustomer") this.typeForm = "deletado";
      if (object.typeForm === "CreateCustomer") this.typeForm = "criado";
      await this.getCustomers();
    },
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async getCustomers(query) {
      try {
        const customers = await CustomerService.getCustomers(query);
        this.customers = customers.data;
      } catch (error) {
        console.error(error);
      }
    },
  },

  watch: {
    page: {
      handler: async function (newPage) {
        const queryParams = {
          page: newPage,
          populate: false,
        };

        if (this.search && this.search.length >= 3) {
          queryParams.search = this.search;
        }

        this.$router.push({ path: "/customers", query: queryParams });
        this.customers.docs = [];
        await this.getCustomers(queryParams);
      },
      deep: true,
    },
    search: {
      handler(newSearch) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(async () => {
          if (newSearch.length >= 3) {
            await this.getCustomers({ search: newSearch, populate: false });
          } else if (newSearch.length === 0) {
            await this.getCustomers({ populate: false });
          }
        }, 1000);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getCustomers();
  },
};
</script>
