<template>
  <div>
    <v-row>
      <v-col>
        <ActionBarCrud
          routePlayers="PlayersRss"
          routeName="CreateRss"
          :create="true"
          :players="false"
          :advancedFilter="false"
        >
          <template v-slot:simpleFilter>
            <v-col cols="3">
              <v-text-field
                label="Nome"
                filled
                rounded
                dense
                v-model="search"
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Carregando..."
          :headers="headers"
          :search="search"
          :items="rss"
          item-key="group"
          class="elevation-8 rounded-lg"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <ActionButtons
              deleteRoute="DeleteRss"
              editRoute="EditRss"
              :item="item"
            />
          </template>

          <template v-slot:[`item.fonteRss`]="{ item }">
            <span class="d-block">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`item.stats`]="{ item }">
            <small>{{ item.contat }}</small>
            <small>Online</small>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <small>{{ item.updatedAt }}</small>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <router-view @created="showSnack"></router-view>
    <v-snackbar v-model="snackbar" color="green">
      Rss {{ data.name }} {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="previewDialog" persistent>
      <RssPreview />
    </v-dialog>
  </div>
</template>
<script>
import ActionBarCrud from "../../components/UI/ActionBarCrud";
import ActionButtons from "../../components/DataTable/ActionButtons";
import ContentService from "../contents/service";
import RssPreview from "./_components/RssPreview";

export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Fonte RSS", value: "name" },
        { text: "Status", value: "stats" },
        { text: "Última atualização", value: "updatedAt" },
        { text: "Operações", value: "actions" },
      ],
      snackbar: false,
      data: {},
      rss: [],
      previewDialog: false,
      typeForm: "criado",
    };
  },
  components: {
    ActionBarCrud,
    ActionButtons,
    RssPreview,
  },
  methods: {
    async showSnack(object) {
      this.data = object.rss;
      this.snackbar = true;
      if (object.typeForm === "CreateRss") this.typeForm = "criado";
      if (object.typeForm === "EditRss") this.typeForm = "editado";
      if (object.typeForm === "DeleteRss") this.typeForm = "deletado";
      await this.getRss();
    },
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async getRss() {
      this.loading = true;
      try {
        const rss = await ContentService.getContents({ type: "RSS" });
        this.rss = rss.data;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.getRss();
  },
};
</script>
