<template>
  <div>
    <v-dialog persistent v-model="dialog">
      <AdvancedFilter @closeFilter="dialog = false"></AdvancedFilter>
    </v-dialog>
    <v-row>
      <v-col>
        <ActionBarCrud
          routeName="CreatePlaylists"
          :create="true"
          :advancedFilter="false"
          @openFilter="dialog = true"
        >
          <template v-slot:simpleFilter>
            <v-col cols="5">
              <v-text-field
                label="Nome:"
                filled
                rounded
                v-model="search"
                dense
                clearable
                hide-details
                background-color="#F5F8FA"
                full-width
              ></v-text-field>
            </v-col>
          </template>
        </ActionBarCrud>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="playlists.docs"
          :search="search"
          item-key="_id"
          class="elevation-8 rounded-lg"
          @click:row="edit"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <ActionButtons :item="item" deleteRoute="DeletePlaylists" />
          </template>
          <template v-slot:[`item.playlist`]="{ item }">
            <span class="d-block">
              {{ item.name }}
              <small class="d-block">{{ item.group }}</small>
            </span>
          </template>
        </v-data-table>
      </v-col>

      <v-col>
        <div class="text-center white rounded-lg elevation-8 rounded-t-0 pa-2">
          <v-pagination
            v-model="page"
            circle
            :length="playlists.totalPages"
            total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <router-view @created="showSnack"></router-view>
    <v-snackbar v-model="snackbar" color="green">
      Playlist {{ typeForm }} com sucesso!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import ActionBarCrud from "../../components/UI/ActionBarCrud";
import ActionButtons from "../../components/DataTable/ActionButtons";
import AdvancedFilter from "./filterplaylists";
import PlaylistService from "./service";
export default {
  data() {
    return {
      dialog: false,
      data: {},
      search: "",
      page: 1,

      headers: [
        { text: "Playlist", value: "name", width: "85%" },
        { text: "Operações", value: "actions" },
      ],
      playlists: [],
      snackbar: false,
      typeForm: "criado",
    };
  },
  components: {
    ActionBarCrud,
    ActionButtons,
    AdvancedFilter,
  },
  methods: {
    async showSnack(object) {
      this.data = object;
      this.snackbar = true;
      if (object.typeForm === "EditPlaylists") this.typeForm = "editado";
      if (object.typeForm === "DeletePlaylists") this.typeForm = "deletado";
      await this.getPlaylist();
    },
    resetForm() {
      this.form = Object.assing({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async edit(event) {
      this.$router.push({ name: "EditPlaylists", params: { id: event._id } });
      console.log(event);
    },
    async getPlaylist(query) {
      try {
        const playlist = await PlaylistService.getPlaylist(query);
        this.playlists = playlist.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    page: {
      handler: async function (newPage) {
        const queryParams = {
          page: newPage,
          populate: false,
        };

        if (this.search && this.search.length >= 3) {
          queryParams.search = this.search;
        }

        this.$router.push({ path: "/playlists", query: queryParams });
        this.playlists.docs = [];
        await this.getPlaylist(queryParams);
      },
      deep: true,
    },
    search: {
      handler(newSearch) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(async () => {
          if (newSearch.length >= 3) {
            await this.getPlaylist({ search: newSearch, populate: false });
          } else if (newSearch.length === 0) {
            await this.getPlaylist({ populate: false });
          }
        }, 1000);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getPlaylist();
  },
};
</script>
