<template>
  <v-card class="rounded-lg pl-6 pr-6 pb-6" width="800">
    <v-card-title>
      <span style="color: DarkCyan" class="text-h5">Filtrar por Categoria</span>

      <v-spacer></v-spacer>

      <v-btn color="white" text @click="closeEmitFilter">
        <v-icon color="#5E6278"> mdi-close </v-icon>
      </v-btn>
    </v-card-title>

    <v-divider class="mb-2"></v-divider>

    <v-row>
      <v-col>
        <v-treeview selectable :items="filterItems"></v-treeview>
      </v-col>
    </v-row>

    <v-divider class="mt-2"></v-divider>

    <v-row class="mt-2">
      <v-col cols="2">
        <v-btn
          @click="closeEmitFilter"
          height="35px"
          class="rounded white--text"
          color="#3DA539"
        >
          Alterar
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "FilterPlaylists",
  data() {
    return {
      dialog: true,

      filterItems: [
        {
          id: 1,
          name: "Anunciante - São Carlos :",
        },
        {
          id: 2,
          name: "Clínica SIM :",
        },
        {
          id: 3,
          name: "Gestão e Infância - Sem Áudio :",
        },
        {
          id: 4,
          name: "Lapa - Checkin fotográfico :",
        },
        {
          id: 5,
          name: "Lapa - Interativa :",
          children: [{ id: 5.1, name: "Checkin fotográfico" }],
        },
        {
          id: 6,
          name: "Playlists - Recife :",
        },
      ],
    };
  },
  methods: {
    handleFile(e) {
      // const selectedFile = e.target.files[0];
      console.log(e);
      this.createBase64File(e);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.file = e.target.result;
      };
      reader.readAsBinaryString(fileObject);
    },
    closeEmitFilter() {
      this.$emit("closeFilter", true);
    },
  },
};
</script>
